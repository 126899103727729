import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { GeneralContainer } from './GeneralContainer'

type Props = {
  slideImage: string
  slideLink: string
  slideText: string
}

export const Slide: React.FC<Props> = ({slideImage, slideLink, slideText}) => {

  return (
    <Section bg={slideImage}>
      <div className="gradient"></div>
      <GeneralContainer>
        <div className="slide-text-container">
          <div>
            <h2>{slideText}</h2>
            <a href={slideLink}>continuar <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 16L21 12M21 12L17 8M21 12L7 12M13 16V17C13 18.6569 11.6569 20 10 20H6C4.34315 20 3 18.6569 3 17V7C3 5.34315 4.34315 4 6 4H10C11.6569 4 13 5.34315 13 7V8" stroke="#FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg></a>
          </div>
        </div>
      </GeneralContainer>
    </Section>
  )
}

type SectionProps = {
  bg: string
}

const Section = styled.div<SectionProps>`
  position: relative;
  height: 320px;
  background: url('${props => props.bg}') 100% 100% no-repeat;
  background-size: cover;
  background-position: center center;

  .gradient {
    position: absolute;
    display: block;
    width: 100%;
    height: 320px;
    z-index: 10;
    background: rgb(255,255,255);
    background: linear-gradient(135deg, rgba(255,255,255,0) 33%, rgba(48,205,222,0.7553396358543417) 75%, rgba(48,205,222,1) 100%);
  }

  .slide-text-container {
    height 320px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;

    >div {
      width: 100%;
      text-align: right;
      z-index: 100;

      @media (min-width: 768px) {
        width: 33%;
      }

      h2 {
        color: #FFF;
        line-height: 28px;
      }

      a {
        color: #FFF;
        font-weight: bold;

        svg {
          position: relative;
          top: 4px;
        }

        &:hover {
          border-bottom: 1px dotted;
        }
      }
    }
  }
`
