import React from 'react'
import styled from 'styled-components'
import { Image } from 'antd'

type Props = {
  name: string
  logo: string
  url: string
}

export const ColaboratorLogo: React.FC<Props> = ({ name, logo, url }) => {
  
  const generatelink = (link:string) => {
    return link.substring(0, 4) == 'http' ?  link : 'http://' + link
  }

  return (
    <ColabLogo>
      {url?
        <a href={generatelink(url)}><Image src={logo} width={200} preview={false} alt={name} /></a>
      :
        <Image src={logo} width={200} preview={false} alt={name} />
      }
    </ColabLogo>
  )
}

const ColabLogo = styled.div`
  a {
    border: 0;

    &:hover {
      text-decoration: none;
    }
  }
`
