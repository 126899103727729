import React from 'react'
import styled from 'styled-components'

type Props = {
  title: string
  icon: string
  firstYear: string
  firstValue: string
  secondYear: string
  secondValue: string
  trend: string
  description: string
  url: string
}

export const HomeIndicatorCard: React.FC<Props> = ({ title, icon, firstYear, firstValue, secondYear, secondValue, trend, description, url }) => {
  return (
    <IndicatorCard icon={icon}>
      <a className="indicator" href={url}>
        <div className="indicator-picture">
          {trend==='up'?
            <svg width="28" height="28" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4.29289 15.7071C3.90237 15.3166 3.90237 14.6834 4.29289 14.2929L9.29289 9.29289C9.68342 8.90237 10.3166 8.90237 10.7071 9.29289L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L10 11.4142L5.70711 15.7071C5.31658 16.0976 4.68342 16.0976 4.29289 15.7071ZM4.29289 9.70711C3.90237 9.31658 3.90237 8.68342 4.29289 8.29289L9.29289 3.29289C9.68342 2.90237 10.3166 2.90237 10.7071 3.29289L15.7071 8.29289C16.0976 8.68342 16.0976 9.31658 15.7071 9.70711C15.3166 10.0976 14.6834 10.0976 14.2929 9.70711L10 5.41421L5.70711 9.70711C5.31658 10.0976 4.68342 10.0976 4.29289 9.70711Z" fill="#ffaa00"/></svg>
            :
            <svg width="28" height="28" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L10.7071 10.7071C10.3166 11.0976 9.68342 11.0976 9.29289 10.7071L4.29289 5.70711C3.90237 5.31658 3.90237 4.68342 4.29289 4.29289C4.68342 3.90237 5.31658 3.90237 5.70711 4.29289L10 8.58579L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289ZM15.7071 10.2929C16.0976 10.6834 16.0976 11.3166 15.7071 11.7071L10.7071 16.7071C10.3166 17.0976 9.68342 17.0976 9.29289 16.7071L4.29289 11.7071C3.90237 11.3166 3.90237 10.6834 4.29289 10.2929C4.68342 9.90237 5.31658 9.90237 5.70711 10.2929L10 14.5858L14.2929 10.2929C14.6834 9.90237 15.3166 9.90237 15.7071 10.2929Z" fill="#374151"/></svg>
          }
        </div>
        <div className="indicator-content">
          <h3>{title}</h3>
          <div className="indicator-content__data">
            <div><small>último dato</small><br/>{firstYear}</div>
            <div><small>objetivo</small><br/>{secondYear}</div>
            <div>{firstValue}</div>
            <div>{secondValue}</div>
          </div>
          <div className="indicator-description">{description}</div>
        </div>
      </a>
    </IndicatorCard>
  )
}

type IndicatorCardProps = {
  icon: string
}

const IndicatorCard = styled.div<IndicatorCardProps>`
  
  .indicator {
    display: grid;
    grid-template-columns: 100px auto auto;
    color: #000;
    transition: all linear .2s;

    &:hover {
      color: #30cdde;
    }

    &-picture {
      position: relative;
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      border-radius: 50%;
      width: 80px;
      height: 80px;
      margin-top: 10px;
      background: #30cdde url('${props => props.icon}') 50% 50% no-repeat;
      background-size: 44px;

      svg {
        position: relative;
        right: 12px;
        bottom: 12px;
      }
    }

    &-content {
      grid-column: 2 / 4;
      grid-row: 1 / 2;

      h3 {
        margin: 0;
        font-size: 16px;
      }

      &__data {
        display: grid;
        grid-template-columns: repeat(2, 120px);
        grid-template-rows: repeat(2, 1fr);
        font-weight: bold;
      }
    }

    &-description {
      grid-column: 1 / 4;
      grid-row: 2 / 3;
      font-size: small;
    }
    
  }
`
