import React from 'react'
import { Table } from 'antd'
import { SortOrder } from 'antd/es/table/interface'
import numeral from 'numeral'
import { SelectedSeries } from '../components/Chart'

type Props = {
  series: SelectedSeries[]
}

export const IndicatorTable: React.FC<Props> = ({ series }) => {
  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      sortDirections: ['ascend', 'descend'] as SortOrder[],
      sorter: (a: any, b: any) => a.nombre.localeCompare(b.nombre),
    },
    ...series[0].values.map(v => ({
      title: String(v.year),
      dataIndex: String(v.year),
      sortDirections: ['ascend', 'descend'] as SortOrder[],
      sorter: (a: any, b: any) => Number(a[v.year]) - Number(b[v.year]),
    })),
  ]

  const data = [
    ...series.map(s => {
      return {
        nombre: s.name,
        ...s.values.reduce((prev, current) => {
          return {
            ...prev,
            [String(current.year)]: current.value ? numeral(current.value).format('0,0.00') : current.value,
          }
        }, {}),
      }
    }),
  ]

  return <Table columns={columns} dataSource={data} pagination={false} scroll={{ y: 400, x: true }} />
}
