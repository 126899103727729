import React from 'react'
import styled from 'styled-components'
import { Row } from 'antd'
import { GeneralContainer } from './GeneralContainer'

type Props = {
  pageTitle: string
}

export const DBVisor: React.FC<Props> = ({ pageTitle }) => {
  return (
    <Visor align="middle">
      <GeneralContainer>{<h1>{pageTitle}</h1>}</GeneralContainer>
    </Visor>
  )
}

const Visor = styled(Row)`
  padding: 25px 0px 10px;
  width: 100%;
  background: url('/layout-images/banner-visor-p.jpg') no-repeat 100% 100%;

  @media only screen and (min-width: 1024px) {
    padding: 50px 0px 10px;
  }

  @media only screen and (min-width: 1440px) {
    background-size: cover;
  }

  h1 {
    color: #fff;
  }
`
