import React from 'react'
import styled from 'styled-components'

type Props = {
  resourceName: string
  resourceUrl: string
  imageUrl: string
}

export const ResourceCard: React.FC<Props> = ({ resourceName, resourceUrl, imageUrl }) => {
  return (
    <ResCard imageUrl={imageUrl}>
      <a href={resourceUrl} target="_blank" />
      <p>
        <a href={resourceUrl} target="_blank">
          {resourceName}
        </a>
      </p>
    </ResCard>
  )
}

type ResCardProps = {
  imageUrl: string
}

const ResCard = styled.div<ResCardProps>`
  > a {
    margin: 0 auto 20px;
    width: 150px;
    height: 200px;
    display: block;
    text-align: center;
    border-radius: 2px;
    background: #ddd url('${props => props.imageUrl}') no-repeat;
    background-size: 150px auto;
    z-index: 100;
    line-height: 150px;
    border: 1px solid #f0f0f0;
    transition: all 0.3 linear;
  }

  p {
    text-align: center;
  }
`
