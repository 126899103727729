import React, { useState } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useParams } from 'react-router-dom'
import { Card } from 'antd'
import useFetch from 'use-http'
import { DefaultLayout } from '../layouts/DefaultLayout'
import { chartTypes, ChartTypesEnum } from '../config/indicators-model'

const charts = [
  {
    key: 'emisiones-gei',
    name: 'Emisiones GEI',
    subCharts: [
      {
        key: 'totales',
        name: 'Totales',
        chartTypes: [ChartTypesEnum.Spline, ChartTypesEnum.Line, ChartTypesEnum.ColumnAllY, ChartTypesEnum.Area],
        series: [],
      },
      {
        key: 'pib',
        name: 'Por PIB',
        chartTypes: [ChartTypesEnum.Spline, ChartTypesEnum.Line, ChartTypesEnum.ColumnAllY, ChartTypesEnum.Area],
        series: [],
      },
    ],
  },
]

type ChartData = {
  data: {
    series: { name: string; data: { year: number; value: number }[] }[]
  }
}

export const Visualization: React.FC = () => {
  const [selectedChartType, setSelectedChartType] = useState('line')
  const { chart, subChart } = useParams<{ chart: string; subChart: string }>()

  const selectedChart = charts.find(c => c.key === chart)
  const selectedSubChart = selectedChart && selectedChart.subCharts.find(sc => sc.key === subChart)

  const fetchUrl = `/data/${chart}/${subChart}/data.json`
  const dataRequest = useFetch<ChartData>(fetchUrl, { responseType: 'json' }, [fetchUrl])
  const chartData = dataRequest.data

  const selectedSeries = chartData?.data.series

  const options = {
    chart: {
      type: selectedChartType,
      // plotBackgroundImage: '/background-test.png',
    },
    title: {
      text: `${selectedChart?.name} - ${selectedSubChart?.name}`,
    },
    credits: false,
    plotOptions: {
      spline: {
        lineWidth: 4,
        states: {
          hover: {
            lineWidth: 5,
          },
        },
        marker: {
          enabled: false,
        },
      },
    },
    xAxis: {
      categories: selectedSeries ? selectedSeries[0].data.map(point => String(point.year)) : [],
    },
    yAxis: {
      title: {
        text: 'Itensidad (CO2eq por PIB)',
      },
      minorGridLineWidth: 0,
      gridLineWidth: 0,
      alternateGridColor: null,
      /*plotBands: [
        {
          from: 275,
          to: 315,
          color: 'rgba(68, 170, 213, 0.1)',
          label: {
            text: 'Polución baja',
            style: {
              color: '#606060',
            },
          },
        },
        {
          from: 315,
          to: 355,
          color: 'rgba(0, 0, 0, 0)',
          label: {
            text: 'Polución media',
            style: {
              color: '#606060',
            },
          },
        },
        {
          from: 355,
          to: 500,
          color: 'rgba(68, 170, 213, 0.1)',
          label: {
            text: 'Polución alta',
            style: {
              color: '#606060',
            },
          },
        },
        {
          // Moderate breeze
          from: 5.5,
          to: 8,
          color: 'rgba(0, 0, 0, 0)',
          label: {
            text: 'Moderate breeze',
            style: {
              color: '#606060',
            },
          },
        },
        {
          // Fresh breeze
          from: 8,
          to: 11,
          color: 'rgba(68, 170, 213, 0.1)',
          label: {
            text: 'Fresh breeze',
            style: {
              color: '#606060',
            },
          },
        },
        {
          // Strong breeze
          from: 11,
          to: 14,
          color: 'rgba(0, 0, 0, 0)',
          label: {
            text: 'Strong breeze',
            style: {
              color: '#606060',
            },
          },
        },
        {
          // High wind
          from: 14,
          to: 15,
          color: 'rgba(68, 170, 213, 0.1)',
          label: {
            text: 'High wind',
            style: {
              color: '#606060',
            },
          },
        },
      ],*/
    },
    series: selectedSeries
      ? selectedSeries.map(series => ({
          name: series.name,
          data: series.data.map(point => point.value),
        }))
      : [],
  }

  const tabList = selectedSubChart!.chartTypes.map(type => ({
    key: type,
    tab: chartTypes[type as ChartTypesEnum].name,
  }))

  return (
    <DefaultLayout
      currentPage={`/visualization/${chart}/${subChart}`}
      title={`${selectedChart?.name} - ${selectedSubChart?.name}`}>
      <Card
        style={{ width: '100%' }}
        tabList={tabList}
        activeTabKey={selectedChartType}
        tabBarExtraContent={<a href="/">More</a>}
        onTabChange={key => {
          setSelectedChartType(key)
        }}>
        {!dataRequest.loading && chartData ? <HighchartsReact highcharts={Highcharts} options={options} /> : ''}
      </Card>
    </DefaultLayout>
  )
}
