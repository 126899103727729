import React from 'react'
import styled from 'styled-components'
import { Col } from 'antd'

export const GeneralContainer: React.FC = ({ children }) => {
  return (
    <Container xs={24} lg={{ span: 22, offset: 1 }} xl={{ span: 20, offset: 2 }} xxl={{ span: 14, offset: 5 }}>
      {children}
    </Container>
  )
}

const Container = styled(Col)`
  padding: 10px;
`
