import React from 'react'
import styled from 'styled-components'

type Props = {
  firstName: string
  lastName: string
  pageUrl: string
  imageUrl: string
}

export const TeamMemberCard: React.FC<Props> = ({ firstName, lastName, pageUrl, imageUrl }) => {
  return (
    <TeamCard imageUrl={imageUrl}>
      <a href={pageUrl} target="_blank" />
      <div>
        {firstName}
        <br />
        {lastName}
      </div>
    </TeamCard>
  )
}

type TeamCardProps = {
  imageUrl: string
}

const TeamCard = styled.div<TeamCardProps>`
  position: relative;
  background: #30cdde;
  display: block;
  margin: 0 auto;
  width: 150px;
  height: 150px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;

  & > div {
    width: 100%;
    position: absolute;
    top: 0;
    color: #fff;
    font-weight: bold;
    text-align: center;
    z-index: 10;
    padding: 35% 0;
  }

  a {
    display: block;
    position: relative;
    
    width: 150px;
    height: inherit;
    background: url('${props => props.imageUrl}') no-repeat;
    background-size: 200px auto;
    background-position: -30px 0;
    opacity: 1;
    transition: 0.5s opacity linear;
    z-index: 100;

    &:hover {
      opacity: 0;
    }
  }
`
