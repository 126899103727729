export const provinces: { [key: string]: { code: string } } = {
  ÁLAVA: { code: 'es-vi' },
  ALBACETE: { code: 'es-ab' },
  'ALICANTE/ALACANT': { code: 'es-a' },
  ALMERÍA: { code: 'es-al' },
  ÁVILA: { code: 'es-av' },
  BADAJOZ: { code: 'es-ba' },
  'BALEARS (ILLES)': { code: 'es-pm' },
  BARCELONA: { code: 'es-b' },
  BURGOS: { code: 'es-bu' },
  CÁCERES: { code: 'es-cc' },
  CÁDIZ: { code: 'es-ca' },
  'CASTELLÓN/CASTELLÓ': { code: 'es-cs' },
  'CIUDAD REAL': { code: 'es-cr' },
  CÓRDOBA: { code: 'es-co' },
  'CORUÑA (A)': { code: 'es-c' },
  CUENCA: { code: 'es-cu' },
  GIRONA: { code: 'es-gi' },
  GRANADA: { code: 'es-gr' },
  GUADALAJARA: { code: 'es-gu' },
  GUIPÚZCOA: { code: 'es-ss' },
  HUELVA: { code: 'es-h' },
  HUESCA: { code: 'es-hu' },
  JAÉN: { code: 'es-j' },
  LEÓN: { code: 'es-le' },
  LLEIDA: { code: 'es-l' },
  'RIOJA (LA)': { code: 'es-lo' },
  LUGO: { code: 'es-lu' },
  MADRID: { code: 'es-m' },
  MÁLAGA: { code: 'es-ma' },
  MURCIA: { code: 'es-mu' },
  NAVARRA: { code: 'es-na' },
  OURENSE: { code: 'es-or' },
  ASTURIAS: { code: 'es-o' },
  PALENCIA: { code: 'es-p' },
  'PALMAS (LAS)': { code: 'es-gc' },
  PONTEVEDRA: { code: 'es-po' },
  SALAMANCA: { code: 'es-sa' },
  'SANTA CRUZ DE TENERIFE': { code: 'es-tf' },
  CANTABRIA: { code: 'es-s' },
  SEGOVIA: { code: 'es-sg' },
  SEVILLA: { code: 'es-se' },
  SORIA: { code: 'es-so' },
  TARRAGONA: { code: 'es-t' },
  TERUEL: { code: 'es-te' },
  TOLEDO: { code: 'es-to' },
  VALENCIA: { code: 'es-v' },
  VALLADOLID: { code: 'es-va' },
  VIZCAYA: { code: 'es-bi' },
  ZAMORA: { code: 'es-za' },
  ZARAGOZA: { code: 'es-z' },
}
