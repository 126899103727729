import Highcharts from 'highcharts'
import { ChartTypesEnum, FinalSeries, isSingleYearType, UnitsEnum } from './indicators-model'
import { provinces } from './provinces'

export const getMapChartOptions = (
  series: FinalSeries[],
  longTitle: string,
  selectedYear: number,
  displayUnit: string | UnitsEnum,
  maxY: number,
  fullScreen: boolean,
) => {
  const selectedChartType = ChartTypesEnum.Map
  const data = series.filter(s => !!provinces[s.name]).map(s => [provinces[s.name].code, s.data[0]])

  return {
    chart: {
      map: 'countries/es/es-all',
      height: fullScreen ? '30%' : '600',
    },
    exporting: {
      enabled: false,
      sourceWidth: 1280,
      sourceHeight: 720,
    },
    title: {
      y: 25,
      text: `${longTitle}${isSingleYearType(selectedChartType) ? ` - ${selectedYear}` : ''}`,
      widthAdjust: -90,
    },
    credits: false,
    tooltip: {
      valueDecimals: 2,
      valueSuffix: ` ${displayUnit}`,
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        verticalAlign: 'bottom',
      },
    },
    colorAxis: {
      min: 0,
      max: maxY,
      minColor: '#eafdff',
      maxColor: '#2fcdde',
    },
    series: [
      {
        data,
        name: selectedYear,
        states: {
          hover: {
            color: '#BADA55',
          },
        },
        dataLabels: {
          enabled: true,
          format: '{point.name}',
        },
      },
      {
        name: 'Separators',
        type: 'mapline',
        data: Highcharts.geojson(Highcharts.maps['countries/es/es-all'], 'mapline'),
        color: 'silver',
        nullColor: 'silver',
        showInLegend: false,
        enableMouseTracking: false,
      },
    ],
  }
}
