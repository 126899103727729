import React from 'react'
import styled from 'styled-components'

type Props = {
  resourceName: string
  resourceUrl: string
  imageUrl: string
}

export const PublicationCard: React.FC<Props> = ({ resourceName, resourceUrl, imageUrl }) => {
  return (
    <PubCard imageUrl={imageUrl}>
      <a href={resourceUrl} target="_blank" rel="noopener noreferrer" />
      <p>
        <a href={resourceUrl} target="_blank" rel="noopener noreferrer">
          {resourceName}
        </a>
      </p>
    </PubCard>
  )
}

type PubCardProps = {
  imageUrl: string
}

const PubCard = styled.div<PubCardProps>`
  > a {
    margin: 0 auto 20px;
    width: 150px;
    height: 150px;
    display: block;
    text-align: center;
    border-radius: 50%;
    background: #ddd url('${props => props.imageUrl}') no-repeat;
    background-size: 220px auto;
    background-position: -30px 0;
    z-index: 100;
    line-height: 150px;
    transition: all 0.3 linear;
  }

  p {
    text-align: center;
  }
`
