import React from 'react'
import styled from 'styled-components'
import { GeneralContainer } from './GeneralContainer'
import { Carousel } from 'antd';
import { Slide } from './Slide'
import { useGetCarouselItems } from '../../services/carousel-items.service'

export const BigVisor: React.FC = () => {

  const carouselItemsRequest = useGetCarouselItems()
  const carouselItems = carouselItemsRequest.data || []

  return (
    <CarouselContainer>
      <OTEACarousel autoplay speed={2000} autoplaySpeed={10000} effect="fade" pauseOnHover >
          <div className="base-slide">
            <GeneralContainer>
              <h1>Observatorio de la<br />Transición Energética<br />y la Acción climática</h1>
            </GeneralContainer>
          </div>
          {carouselItems?.map(i => (
            <Slide
              key={i.id} 
              slideImage={i.attachment[0]?.url}
              slideLink={i.url}
              slideText={i.description} />
          ))}
      </OTEACarousel>
    </CarouselContainer>
  )
}

const CarouselContainer = styled.div`
  width: 100%;
  height: 320px;
  margin: 0 0 80px 0;

  @media (min-width: 1440px) {
    margin: 80px 0;
  }
`

const OTEACarousel = styled(Carousel)`
  height: 320px;

  .base-slide {
    padding: 65px 0px 10px;
    background: url('/layout-images/banner-visor.jpg') no-repeat;
    background-position: center center;
    height: 320px;

    @media (min-width: 1440px) {
      background-size: cover;
    }

    h1 {
      color: #FFF;
    }
  }
`

