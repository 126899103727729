import ReactGA from "react-ga4";

export const colors = {
  alternatives: [
    '#2fcdde',
    '#5bba78',
    '#f9b454',
    '#83a1c1',
    '#fc9ca4',
    '#a4fc9c',
    '#ecf953',
    '#C0C6FF',
    '#f96254',
    '#527e4e',
    '#8d9531',
    '#6d72b0',
    '#ae443a',
    '#dddddd',
    '#666666',
    '#2aa5a5',
    '#fdd7da',
    '#e3e6ff',
    '#B4DADE',
    '#A0C7AC',
    '#FCDBAE',
    '#BFCBD7',
    '#81B0B5',
    '#FFCC7E',
    '#DF5B4F',
    '#111111',
    '#B47C28',
  ],
}

export const getAbsoluteServerUrl = (relativeServerUrl: string) => {
  return `https://api.otea.info/api${relativeServerUrl}`
}

const GAnalyticsIDKey = 'G-HTXCZ1M8F2';

export const initGA = () => {
  ReactGA.initialize(GAnalyticsIDKey);
};
