import React, { useState } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useParams } from 'react-router-dom'
import { Card, Layout } from 'antd'
import useFetch from 'use-http'
import HighchartsMore from 'highcharts/highcharts-more'
import HighchartsPatternFill from 'highcharts/modules/pattern-fill'
// @ts-ignore
import HighchartsGroupedCategories from 'highcharts-grouped-categories'
import { ChartTypesEnum } from '../config/indicators-model'
import { colors } from '../config/config'

const chartTypeMap = (chartType: ChartTypesEnum) => {
  if (chartType === ChartTypesEnum.Column1Y) {
    return ChartTypesEnum.ColumnAllY
  }

  return chartType
}

const colorMap: { [key: string]: any } = {
  Energía: '#D3BE4E',
  IPPU: '#4EC3D3',
  Agricultura: '#42B649',
  Residuos: '#D38E4E',
  Bunkers: {
    pattern: {
      path: {
        d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
        strokeWidth: 3,
      },
      width: 10,
      height: 10,
      color: '#444444',
    },
  },
  Electricidad: '#D3BE4E',
  Petróleo: '#001529', // negro
  Carbón: '#B98155', // marrón oscuro
  Gas: '#D34ECE', // fucsia
  2016: '#D34ECE',
  2017: '#D38E4E',
  2018: '#D3BE4E',
  2019: '#4EC3D3',
  2020: '#42B649',
  'Emisiones 2018': '#D3BE4E',
  'Emisiones 2019': '#4EC3D3',
  'Emisiones 2020': '#42B649',
  'Emisiones 2020 sin COVID-19': '#A6A6A6',
  'Emisiones Fugitivas': '#4EC3D3',
  'Biomasa (CO2 excluido)': '#42B649',
  'Otros combustibles fósiles': '#D3504E',
  'Total combustibles': {
    pattern: {
      path: {
        d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
        strokeWidth: 3,
      },
      width: 10,
      height: 10,
      color: '#001529',
    },
  },
}

// Años
// Fucsia 2016
// Color que pegue 2017
// Amarillo 2018
// Azul 2019
// Verde 2020

// waterfall en amarillo los normales

HighchartsPatternFill(Highcharts)
HighchartsMore(Highcharts)
HighchartsGroupedCategories(Highcharts)
/*Highcharts.setOptions({
  chart: {
    style: {
      fontSize: '15px',
    },
  },
  xAxis: {
    labels: {
      step: 1,
      style: {
        fontSize: '13px',
      },
    },
  },
  yAxis: {
    labels: {
      style: {
        fontSize: '13px',
      },
    },
  },
  colors: colors.alternatives,
  lang: {
    thousandsSep: '.',
    decimalPoint: ',',
  },
})*/

export const Figures: React.FC = () => {
  const [selectedChartType, setSelectedChartType] = useState(ChartTypesEnum.Line)
  const { figure, size } = useParams<{ figure: string; size?: string }>()

  const chartWidth = size ? Number(size.split('x')[0]) : '100%'
  const chartHeight = size ? Number(size.split('x')[1]) : 800

  const metadataUrl = '/data/figures/metadata.json'
  const metadataRequest = useFetch(metadataUrl, { responseType: 'json' }, [])
  // const chartMetaData = metadataRequest.data ? transformRawIndicatorData(metadataRequest.data) : undefined

  const dataUrl = '/data/figures/data.json'
  const dataRequest = useFetch(dataUrl, { responseType: 'json' }, [])

  if (!metadataRequest.data || !dataRequest.data) {
    return <></>
  }

  const selectedFigure = metadataRequest.data.find((item: any) => {
    return item.FIGURAS === `Figura ${figure}`
  })

  const selectedData = dataRequest.data.filter((item: any) => item.NOMBRE === selectedFigure.FIGURAS)

  const groupedSeries: { [key: string]: { values: number[] } } = selectedData.reduce((prev: any, current: any) => {
    const mapKey = current.SUBCATEGORIA || current.CATEGORIA || 'N/A'

    const val = selectedFigure.PERCENTAGE === '1' ? Number(current.METRICA) * 100 : Number(current.METRICA)

    const newValue = {
      [mapKey]:
        prev[mapKey] === undefined
          ? { values: [val] }
          : {
              ...prev[mapKey],
              values: [...prev[mapKey].values, val],
            },
    }

    return {
      ...prev,
      ...newValue,
    }
  }, {})

  const simpleCategories: { [key: string]: { name: string } } = selectedData.reduce((prev: any, current: any) => {
    const mapKey = `${current.EJE_X}-${current.CATEGORIA}` || 'N/A'

    const newValue = prev[mapKey]
      ? {
          ...prev[mapKey],
          categories: [...new Set([...prev[mapKey].categories, current.CATEGORIA])],
        }
      : {
          name: current.EJE_X,
          categories: [current.CATEGORIA],
        }

    return {
      ...prev,
      [mapKey]: newValue,
    }
  }, {})

  const groupedCategories: { [key: string]: { name: string; categories: string[] } } = selectedData.reduce(
    (prev: any, current: any) => {
      const mapKey = `${current.EJE_X}` || 'N/A'

      const newValue = prev[mapKey]
        ? {
            ...prev[mapKey],
            categories: [...new Set([...prev[mapKey].categories, current.CATEGORIA])],
          }
        : {
            name: current.EJE_X,
            categories: [current.CATEGORIA],
          }

      return {
        ...prev,
        [mapKey]: newValue,
      }
    },
    {},
  )

  const categories =
    selectedFigure.IND_GROUPED_CATEGORY === '1'
      ? Object.entries(groupedCategories).map(([_key, value]) => ({ name: value.name, categories: value.categories }))
      : Object.entries(simpleCategories).map(([_key, value]) => value.name)

  /*const categories = [
    { name: '2016', categories: ['Estimaciones', 'Inventario'] },
    { name: '2017', categories: ['Estimaciones', 'Inventario'] },
    { name: '2018', categories: ['Estimaciones', 'Inventario'] },
    { name: '2019', categories: ['Estimaciones', 'Inventario'] },
  ]*/

  // const categories = selectedData.map((item: any) => item.EJE_X)

  const series = Object.entries(groupedSeries).map(([key, group], index) => {
    // const color = allColors.length > index + 1 ? { color: allColors[index] } : {}
    const color = selectedFigure.THRESHOLD !== '' ? { color: '#D3504E' } : colorMap[key] ? { color: colorMap[key] } : {}

    const finalValues =
      selectedFigure.TIPO_GRAFICO === 'waterfall'
        ? [
            { y: group.values[0], color: '#4EC3D3' },
            ...group.values.slice(1, -1).map(y => ({ y, color: '#D3BE4E' })),
            { color: '#42B649', isSum: true, y: group.values[group.values.length - 1] },
          ]
        : figure === '13' && key === '2020'
        ? group.values.map((val, index) => {
            return index >= 9
              ? {
                  y: val,
                  color: {
                    pattern: {
                      path: {
                        d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                        strokeWidth: 3,
                      },
                      width: 10,
                      height: 10,
                      ...color,
                    },
                  },
                }
              : val
          })
        : figure === '22'
        ? group.values.map((val, index) => {
            const yearColors = ['#D3BE4E', '#4EC3D3', '#001529', '#42B649']
            return {
              y: val,
              color:
                index === group.values.length - 2
                  ? {
                      pattern: {
                        path: {
                          d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                          strokeWidth: 3,
                        },
                        width: 10,
                        height: 10,
                        color: '#A6A6A6',
                      },
                    }
                  : yearColors[index],
            }
          })
        : ['26', '27'].includes(figure)
        ? group.values.map((val, index) => {
            const localColor = figure === '26' ? '#42B649' : '#D38E4E'
            return {
              y: val,
              color:
                index % 2 === 1
                  ? {
                      pattern: {
                        path: {
                          d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                          strokeWidth: 3,
                        },
                        width: 10,
                        height: 10,
                        color: localColor,
                      },
                    }
                  : localColor,
            }
          })
        : group.values

    const zone =
      figure.split('.')[0] === '12' && key === '2020'
        ? { zoneAxis: 'x', zones: [{ value: 8 }, { dashStyle: 'Dash' }] }
        : undefined

    const dashStyle = key === 'Emisiones 2020 sin COVID-19' ? { dashStyle: 'Dash' } : undefined

    return {
      name: key,
      ...color,
      ...(selectedFigure.THRESHOLD !== '' ? { negativeColor: '#42B649' } : {}),
      data: finalValues,
      ...zone,
      ...dashStyle,
      legendIndex: index,
    }
  })

  const errorSeries =
    selectedFigure.ERROR_BAR !== '1'
      ? []
      : [
          {
            name: 'Rango de error',
            type: 'errorbar',
            data: selectedData.map((item: any) =>
              item.ERROR_BAJO === '' ? [] : [Number(item.ERROR_BAJO) * 100, Number(item.ERROR_ALTO) * 100],
            ),
          },
        ]

  const options = {
    chart: {
      type: chartTypeMap(selectedFigure.TIPO_GRAFICO),
      // width: 800,
      height: chartHeight,
    },
    legend: {
      enabled: selectedFigure.LEGEND === '1',
    },
    title: {
      text: selectedFigure.TITLE,
    },
    credits: false,
    plotOptions: {
      column: {
        ...(selectedFigure.IND_STACKED === '1' ? { stacking: 'normal' } : {}),
        dataLabels: ['1', '7'].includes(figure)
          ? {
              padding: -100,
              enabled: true,
              formatter: function () {
                const currentPoint = this as any
                if (currentPoint.point.index < currentPoint.series.data.length - 1) {
                  return
                }

                return `${Highcharts.numberFormat(currentPoint.point.y, 0)}%`
              },
            }
          : {},
      },
      area: {
        ...(selectedFigure.IND_STACKED === '1' ? { stacking: 'normal' } : {}),
      },
      waterfall: {
        borderWidth: 0,
      },
    },
    xAxis: {
      categories,
      labels: {
        formatter: function () {
          const point: any = this

          const value = typeof point.value === 'string' ? point.value : point.value.name

          const [label, index] = value.split('###')
          if (index && Number(index) > 1) {
            return ''
          }

          return label
        },
      },
    },
    yAxis: {
      title: {
        text: selectedFigure.METRIC,
      },
      minorGridLineWidth: 0,
      gridLineWidth: 0,
      alternateGridColor: '#f8fcf8',
      ...(selectedFigure.PERCENTAGE === '1'
        ? {
            labels: {
              format: '{value:.0f}%',
            },
          }
        : {}),
      ...(selectedFigure.TIPO_GRAFICO === 'waterfall' ? { min: 250 } : {}),
    },
    series: selectedFigure.IND_STACKED === '1' ? [...series, ...errorSeries].reverse() : [...series, ...errorSeries],
  }

  return (
    <Layout style={{ backgroundColor: '#FFFFFF' }}>
      <Card
        style={{ width: chartWidth, height: chartHeight, border: 0 }}
        activeTabKey={selectedChartType}
        tabBarExtraContent={<a href="/">More</a>}
        onTabChange={key => {
          setSelectedChartType(key as ChartTypesEnum)
        }}>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          callback={(chart: any) => {
            if (selectedFigure.THRESHOLD === '') {
              return
            }
            // TODO: Enable only when negative active
            var dExt
            var ext = chart.yAxis[0].getExtremes()
            var dMax = Math.abs(ext.dataMax)
            var dMin = Math.abs(ext.dataMin)
            dMax >= dMin ? (dExt = dMax) : (dExt = dMin)
            var min = 0 - dExt // TODO: Set threshold
            chart.yAxis[0].setExtremes(min, dExt)
          }}
        />
      </Card>
    </Layout>
  )
}
