import { Url } from 'url'
import { useFetch } from 'use-http'
import { getAbsoluteServerUrl } from '../config/config'

type HomeIndicator = { 
  id: number;
  title: string; 
  attachment: { url: string; }[];
  firstYear: string;
  firstValue: string;
  secondYear: string;
  secondValue: string;
  trend: string;
  description: string;
  url: string;
}

export function useGetHomeIndicators() {
  return useFetch<HomeIndicator[]>(getAbsoluteServerUrl('/homeindicators'), { responseType: 'json' }, [])
}
