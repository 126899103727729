import { UnitsEnum } from './indicators-model'

export const conversions: { [key: string]: { name: UnitsEnum; conversion: number }[] } = {
  [UnitsEnum.MtCO2eq]: [
    {
      name: UnitsEnum.ktCO2eq,
      conversion: 1000,
    },
    {
      name: UnitsEnum.tCO2eq,
      conversion: 1000000,
    },
  ],
}

export const convert = (from: UnitsEnum, to: UnitsEnum, value: number) => {
  const fromFound = Object.entries(conversions).find(([key, items]) => {
    if (key === from) {
      return [key, items]
    }

    if (items.find(v => v.name === from)) {
      return [key, items]
    }

    return false
  })

  if (!fromFound) {
    throw new Error(`Unable to convert from ${from} to ${to} (C1)`)
  }

  const [mainUnit, childUnits] = fromFound
  if (mainUnit === from) {
    const selectedChild = childUnits.find(c => c.name === to)
    if (!selectedChild) {
      throw new Error(`Unable to convert from ${from} to ${to} (C2)`)
    }

    return value * selectedChild.conversion
  }

  const selectedFromChild = childUnits.find(c => c.name === from)
  if (!selectedFromChild) {
    throw new Error(`Unable to convert from ${from} to ${to} (C3)`)
  }

  if (mainUnit === to) {
    return value / selectedFromChild.conversion
  }

  const selectedToChild = childUnits.find(c => c.name === to)
  if (selectedToChild) {
    return (value / selectedFromChild.conversion) * selectedToChild.conversion
  }

  throw new Error(`Unable to convert from ${from} to ${to} (C4)`)
}

export const convertUsingMultiplier = (
  from: string,
  to: string,
  availableUnits: string[],
  multiplier: number,
  multiplier2: number,
  value: number,
) => {
  const toPosition = availableUnits.indexOf(to)

  if (toPosition === 1) {
    return value * multiplier
  }

  if (toPosition === 2) {
    return value * multiplier2
  }

  throw new Error(`Unable to convert ${from} to ${to}`)
}
