import React from 'react'
import styled from 'styled-components'

type Props = {
  reportName: string
  reportUrl: string
  imageUrl: string
}

export const ReportCard: React.FC<Props> = ({ reportName, reportUrl, imageUrl }) => {
  return (
    <RepCard imageUrl={imageUrl}>
      <a href={reportUrl} target="_blank" />
      <p>
        <a href={reportUrl} target="_blank">
          {reportName}
        </a>
      </p>
    </RepCard>
  )
}

type RepCardProps = {
  imageUrl: string
}

const RepCard = styled.div<RepCardProps>`
  > a {
    margin: 0 auto 20px;
    width: 200px;
    height: 114px;
    display: block;
    text-align: center;
    border-radius: 2px;
    background: #ddd url('${props => props.imageUrl}') no-repeat;
    background-size: 200px auto;
    z-index: 100;
    line-height: 114px;
    border: 1px solid #f0f0f0;
    transition: all 0.3 linear;
  }

  p {
    text-align: center;
  }
`
